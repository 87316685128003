import camelCase from "./utils/camelCase";
import AOS from "aos";
import { gsap } from "gsap";
import { slick } from "slick-carousel";
import matchHeight from "jquery-match-height";

const checkForElement = (element) => {
	if (typeof element != "undefined") {
		return element;
	}
	return false;
};

document.addEventListener("DOMContentLoaded", () => {
	// Match Height Helper Class
	$(".match-height-row").matchHeight({
		byRow: true,
		property: "min-height",
	});
	$(".match-height").matchHeight({
		byRow: false,
		property: "min-height",
	});

	$(".carousels").slick();

	$('.close-pop-up').on('click', function(e){
		e.preventDefault();
		$('.popup-container').fadeOut();
	})

	$('.section-selector a').on('click', function(e){
		e.preventDefault();
		var target = $(this).attr('href');
		$('.section-selector a').removeClass('active');
		$(this).addClass('active');
		$('.faqs-wrapper').fadeOut(function(){
			$(target).fadeIn();
		});
	})

	// init AOS
	var aosOffset = 150,
		aosDuration = 400;
	if ($("#mobile-tester").is(":visible")) {
		aosOffset = -50;
		aosDuration = 450;
	}

	AOS.init({
		duration: aosDuration,
		easing: "ease-out-cubic",
		startEvent: "load",
		offset: aosOffset,
	});

	// Mobile Menu
	$(".mobile-trigger").on("click", function (e) {
		e.preventDefault();
		$(this).toggleClass("active");
		$("#mobile-navigation").slideToggle();
	});

	$(
		".mobile-menu .sub-menu, .mobile-menu .mega-menu, #mobile-navigation"
	).hide();

	$(".has-sub-menu > a").on("click", function (e) {
		e.preventDefault();
		$(this).toggleClass("active");
		$(this).parent("li").find("> .sub-menu").slideToggle();
	});

	// $(".has-mega-menu > a").on("click", function (e) {
	// 	e.preventDefault();
	// 	$(this).toggleClass("active");
	// 	$(this).parent("li").find("> .mega-menu").slideToggle();
	// });

	$(".has-mega-menu").hover(function (e) {
		$(this).addClass("active");
		$(this).parent("li").find("> .mega-menu").stop(true,true).slideDown();
	}, function(){

		$(this).removeClass("active");
		$(this).parent("li").find("> .mega-menu").stop(true,true).slideUp();
	});

	const blocks = document.querySelectorAll(".husl-block");
	if (!blocks.length) return;

	var blocksArray = [];
	blocks.forEach(async (module) => {
		let name = checkForElement(module.dataset.block);
		if (name) {
			name = camelCase(name);
			if ($.inArray(name, blocksArray) == -1) {
				blocksArray.push(name);
			}
		} else {
			console.error("No block name found");
			return;
		}
	});

	if (blocksArray.length) {
		blocksArray.forEach(async (name) => {
			try {
				const { Block } = await import(
					/* webpackChunkName: 'block' */ `./blocks/${name}`
				);
				const block = new Block(name);
				block.init();
			} catch (error) {
				console.log(error);
				console.warn(
					`You're missing the js file for the ${name} block. \n Create the missing file inside the blocks folder: nylon/assets/js/blocks/${name}.js`
				);
			}
		});
	}
});
